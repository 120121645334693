import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'lunch',
  formId: 'DK%20Lunch',
  title: 'Frokost',
  subTitle: 'Kærlighed ved første bid.',
  icon: '/icons/lunch.svg',
  image: '/assets/images/sunrise/lunch_supermercat.png',
  theme: 'sunrise-lunch',
  sections: [
    {
      sectionId: 'hero',
      label: 'Frokost',
      title: 'Kærlighed ved første bid.',
      description:
        'Vi har en bred vifte af leverandører med fokus på årstidens friske råvarer til enhver pengepung. Vælg selv om det hele skal være økologisk, hvad enten I er veganere, kødelskere eller begge dele, og hvis du ikke kan lide lugten i køkkenet - så prøver du bare et andet...',
      image: '/assets/images/sunrise/lunch_supermercat.png',
      icon: '/icons/lunch.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Sammen eller hver for sig!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/lunch/service_1.svg',
          title: 'Buffet',
          description:
            'Klassikeren! Når du sidder på kontoret og ikke lige ved hvad du har lyst til, så er et stilfuldt præsenteret udvalg lige sagen. Bliv inspireret af kollegaernes valg.',
        },
        {
          icon: '/assets/images/services/lunch/service_2.svg',
          title: 'Individuel servering',
          description:
            'Når du er travl og bare skal have noget i hånden - eller hvis du vil være sikker på at naboen ikke har nyst på maden.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/lunch/journey_1.svg',
          title: 'Fortæl os, hvad I kan lide',
          description: 'Hvad skaber energi og forventningsglæde på netop jeres kontor?',
        },
        {
          icon: '/assets/images/services/lunch/journey_2.svg',
          title: 'Få en smagsprøve',
          description: 'Vi sørger for, at du kan smage på varerne, før du vælger køkken.',
        },
        {
          icon: '/assets/images/services/lunch/journey_3.svg',
          title: 'Vælg din favorit',
          description: 'Fortæl os hvad du elsker mest af alle smagsprøverne.',
        },
        {
          icon: '/assets/images/services/lunch/journey_4.svg',
          title: 'Ja tak!',
          description: 'Det var det. Nu er det bare om at opbygge appetit til frokost gong gongen lyder.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker alle Good Mondays frokostordning?',
      description: '',
      image: '/assets/images/services/lunch/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-activity-outline',
          title: 'Flexibilitet',
          description: 'Vil du have mere eller mindre? Du kan justere aftale dag til dag!',
        },
        {
          icon: '',
          title: 'Få en test-uge',
          description: 'Du behøver ikke at gætte hvad der passere bedst til jer, men får lov at prøve først.',
        },
        {
          icon: 'eva eva-clock-outline',
          title: 'Ultra kort opsigelsesvarsel!',
          description: '14 dage! Slå den!',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du at Good Monday har frokost til enhver smag og pengepung?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
